import {useState, useEffect, useMemo, useRef, useCallback} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {QSPendingTable} from '../../modules/widgets/quotation/QSPendingTable'
import {QSPending, QSPendingUpdate} from '../../modules/api/requests/QuotationRequest'
import {ReturnImage} from '../../modules/widgets/quotation/func/ReturnImage'
import {CoverTime4, CovertTime} from '../../modules/widgets/func/CoverTime'
import {OpenFile} from '../../modules/widgets/func/OpenFile'
import {ReturnNull} from '../../modules/widgets/func/ReturnNull'
import {ReadMore} from '../../modules/widgets/func/ReadMore'
import {NewItem} from '../../modules/widgets/quotation/pending/NewItem'
import {PendingShow} from '../../modules/widgets/quotation/pending/Show'
import {NewSupplier} from '../../modules/widgets/quotation/pending/NewSupplier'
import {useAuth} from '../../modules/auth'
import Swal from 'sweetalert2'
import {SendReturnRequirement} from '../../modules/api/requests/MailRequest'

const QSForm = () => {
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = useRef(0)

  const [count, setCount] = useState(0)
  const [newdata, setNewData] = useState([
    {
      id: 0,
      status: 'none',
      gp_verify_id: 0,
      gp_verify_comment: '',
      remark_content: '',
    },
  ])

  let gp: number = currentUser?.id || 0
  let gp_name: string = currentUser?.first_name || ''

  const [index, setIndex] = useState(0)
  const [size, setSize] = useState(0)
  const [filters, setFilters] = useState('')

  const fetchAPI = async ({limit, skip, search}: any) => {
    try {
      let lt = limit || 10
      let sk = skip || 0
      let sr: any = encodeURIComponent(JSON.stringify(search || null))
      const res: any = await QSPending(lt, sk, sr)
      let data: any = res.data.data
      let count: number = res.data.count
      count = count / 1
      setData(data)
      setPageCount(Math.ceil(count / limit))
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, filter}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    setSize(pageSize)
    setIndex(pageIndex)
    setFilters(filter)
    if (fetchId == fetchIdRef.current) {
      fetchAPI({limit: pageSize, skip: pageSize * pageIndex, search: filter})
    }
  }, [])

  const getData = () => {
    fetchAPI({limit: size || 10, skip: size * index || 0, search: filters || ''})
  }

  const clearData = () => {
    setNewData([])
    setCount(0)
    getData()
  }

  const disabledReturn = (id: number, bool: boolean) => {
    let submitBox = document.getElementById(`submit_${id}`) as HTMLInputElement
    let returnBox = document.getElementById(`return_${id}`) as HTMLInputElement
    if (submitBox.checked == false) {
      if (bool == true) {
        returnBox.disabled = true
        returnBox.checked = false
        submitBox.disabled = false
      } else if (bool == false) {
        returnBox.disabled = false
      }
    }
  }

  const comment = (cell: any, comment: string) => {
    let dataset: any = []
    dataset = newdata
    if (typeof comment === 'undefined' || comment == '' || comment == null) {
      const res: any = dataset.find((val: any) => val.id == cell.id)
      if (typeof res === 'undefined' || res == '') {
        let newObj: any = {}
        newObj.id = cell.id
        newObj.status = cell.status
        newObj.gp_verify_id = gp
        newObj.gp_verify_comment = cell.gp_verify_comment
        newObj.remark_content = cell.remark_content
        dataset.push(newObj)
      } else {
        if (res.status == 'return') {
          res.status = 'none'
        }
        res.gp_verify_comment = cell.gp_verify_comment
      }
      disabledReturn(cell.id, true)
    } else {
      const res: any = dataset.find((val: any) => val.id == cell.id)
      if (typeof res === 'undefined' || res == '') {
        let newObj: any = {}
        newObj.id = cell.id
        newObj.status = cell.status
        newObj.gp_verify_id = gp
        newObj.gp_verify_comment = comment
        newObj.remark_content = cell.remark_content
        dataset.push(newObj)
      } else {
        res.gp_verify_comment = comment
      }
      disabledReturn(cell.id, false)
    }
    setNewData(dataset)
    ChangeCount()
  }

  const remark = (cell: any, content: string) => {
    let dataset: any = newdata || []
    if (typeof content === 'undefined' || content == '' || content == null) {
      const res: any = dataset.find((val: any) => val.id == cell.id)
      if (typeof res === 'undefined' || res == '') {
        let newObj: any = {}
        newObj.id = cell.id
        newObj.status = cell.status
        newObj.gp_verify_id = gp
        newObj.gp_verify_comment = cell.gp_verify_comment
        newObj.remark_content = cell.remark_content
        dataset.push(newObj)
      } else {
        if (res.status == 'return') {
          res.status = 'none'
        }
        res.remark_content = cell.gp_verify_comment
      }
    } else {
      const res: any = dataset.find((val: any) => val.id == cell.id)
      if (typeof res === 'undefined' || res == '') {
        let newObj: any = {}
        newObj.id = cell.id
        newObj.status = cell.status
        newObj.gp_verify_id = gp
        newObj.gp_verify_comment = cell.gp_verify_comment
        newObj.remark_content = content
        dataset.push(newObj)
      } else {
        res.remark_content = content
      }
    }
    setNewData(dataset)
    ChangeCount()
  }

  const ChangeCount = () => {
    const number: any = newdata.filter((val: any) => val.status != 'none')
    setCount(number.length)
  }

  const handleData = (cell: any, key: string, value: any) => {
    const bool: any = newdata.some((val: any) => val.id == cell.id)
    if (bool) {
      setNewData((obj: any) =>
        obj.map((val: any) => {
          if (val.id == cell.id) {
            if (key == 'status') {
              if (typeof value === 'object' && value !== null) {
                let status: string = 'none'
                if (value.status == 'submit' && value.bool == true) {
                  status = 'submit'
                } else if (value.status == 'return' && value.bool == true) {
                  status = 'return'
                }
                val.status = status
              }
            }
          }
          return val
        })
      )
    } else {
      if (key == 'status') {
        if (typeof value === 'object' && value !== null) {
          let status: string = 'none'
          if (value.status == 'submit' && value.bool == true) {
            status = 'submit'
          } else if (value.status == 'return' && value.bool == true) {
            status = 'return'
          }
          setNewData([
            ...newdata,
            {
              id: cell.id,
              status: status,
              gp_verify_id: gp,
              gp_verify_comment: cell.gp_verify_comment,
              remark_content: cell.remark_content,
            },
          ])
        }
      }
    }
    ChangeCount()
  }

  const disabledChekd = (id: number) => {
    const res: any = newdata.find((val) => val.id == id)
    if (typeof res === 'undefined' || res == null) {
      return true
    } else {
      if (res.status == 'submit') {
        return true
      } else if (res.status == 'return') {
        return false
      } else if (res.gp_verify_comment == '' || res.gp_verify_comment == null) {
        return true
      } else {
        return false
      }
    }
  }

  const submit = async () => {
    let countR: number = 0
    for (let i = 0; i < newdata.length; i++) {
      if (
        newdata[i].status == 'return' &&
        (newdata[i].gp_verify_comment == null || newdata[i].gp_verify_comment == '')
      ) {
        countR = countR + 1
      }
    }

    const newDataSet = newdata?.filter((v: any) => v.id)
    const dataReturn = newDataSet?.filter((v: any) => v?.status == 'return')?.map((v: any) => v?.id)

    if (countR > 0) {
      Swal.fire({
        title: 'Please check the comments.',
        text: 'If you choose to return, please fill in the information in the comment box.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    } else {
      Swal.fire({
        title: 'Are you confirm to save?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            if (newDataSet.length > 0) {
              await QSPendingUpdate(newDataSet)
                .then(async (res) => {
                  if (res.data.status == true) {
                    if (dataReturn?.length > 0) {
                      await SendReturnRequirement(dataReturn)
                    }
                    Swal.fire({
                      title: 'Successful created!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    }).then(() => {
                      setCount(0)
                      setNewData([])
                      clearData()
                    })
                  } else {
                    Swal.fire({
                      title: res.data.message,
                      text: '',
                      icon: 'error',
                      heightAuto: false,
                      returnFocus: false,
                    })
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    text: '',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            } else {
              Swal.fire({
                title: 'No added information found.',
                text: 'Please add information first.',
                icon: 'error',
                heightAuto: false,
                returnFocus: false,
              })
            }
          })
        },
      })
    }
  }

  const column = useMemo(
    () => [
      {
        Header: 'From User',
        headerClassName: `bg-light-warning`,
        columns: [
          {
            Header: 'Branch',
            id: 'branch',
            headerClassName: 'bg-light-warning min-w-130px  headcol-left',
            columnClassName: 'rowcol-left',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReadMore(cell.row.original.branch_title)}
              </div>
            ),
          },
          {
            Header: 'Section/Department',
            id: 'sect',
            headerClassName: 'bg-light-warning min-w-130px headcol-left',
            columnClassName: 'rowcol-left',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReadMore(cell.row.original.dept_name)}
              </div>
            ),
          },
          {
            Header: 'Item',
            id: 'item',
            headerClassName: 'bg-light-warning min-w-130px headcol-left',
            columnClassName: 'rowcol-left',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReadMore(cell.row.original.item_title || cell.row.original.item_other)}
              </div>
            ),
          },
          {
            Header: <div>Item&nbsp;Description</div>,
            id: 'item_description',
            headerClassName: 'bg-light-warning min-w-150px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                <span className='word-break'>{ReadMore(cell.row.original.item_description)}</span>
              </div>
            ),
          },
          {
            Header: 'QTY',
            id: 'qty',
            accessor: 'qty',
            headerClassName: 'bg-light-warning min-w-50px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReturnNull(cell.row.original.qty)}
              </div>
            ),
          },
          {
            Header: 'Unit',
            id: 'unit',
            headerClassName: 'bg-light-warning min-w-100px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReturnNull(cell.row.original.unit_title)}
              </div>
            ),
          },

          {
            Header: <div>PIC</div>,
            id: 'file_url_pic',
            accessor: 'file_url_pic',
            headerClassName: 'bg-light-warning min-w-50px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReturnImage(cell.row.original.file_url_pic)}
              </div>
            ),
          },
          {
            Header: <div>FILE</div>,
            id: 'file_url_draw',
            accessor: 'file_url_draw',
            headerClassName: 'bg-light-warning min-w-50px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {OpenFile(cell.row.original.file_url_draw, 'draw')}
              </div>
            ),
          },
          {
            Header: <div>QTO&nbsp;User</div>,
            id: 'file_url_qto',
            accessor: 'file_url_qto',
            headerClassName: 'bg-light-warning min-w-50px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {OpenFile(cell.row.original.file_url_qto, 'quotation')}
              </div>
            ),
          },
          {
            Header: <>Requisition&nbsp;Date</>,
            id: 'created_at',
            headerClassName: 'bg-light-warning min-w-150px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {CoverTime4(cell.row.original.created_at)}
              </div>
            ),
          },
          {
            Header: <>Remark</>,
            id: 'remark_content',
            headerClassName: 'bg-light-warning min-w-200px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                <textarea
                  rows={1}
                  id={'remark_' + cell.row.original.id}
                  className='form-control form-control-sm input-pending'
                  defaultValue={cell.row.original.remark_content}
                  onChange={(e: any) => {
                    remark(cell.row.original, e.target.value)
                  }}
                ></textarea>
              </div>
            ),
          },
          {
            Header: <>User&nbsp;Last&nbsp;Remark</>,
            id: 'remark_user_name',
            headerClassName: 'bg-light-warning min-w-150px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                {ReturnNull(cell.row.original.remark_user_name)}
              </div>
            ),
          },
        ],
      },
      {
        Header: 'GP Verification',
        headerClassName: 'bg-light-primary',
        columns: [
          {
            Header: 'Comment',
            headerClassName: 'bg-light-primary min-w-200px',
            Cell: ({cell}: any) => (
              <div>
                <textarea
                  rows={1}
                  id={'comment_' + cell.row.original.id}
                  className='form-control form-control-sm input-pending'
                  onChange={(e) => {
                    comment(cell.row.original, e.target.value)
                  }}
                  defaultValue={
                    newdata
                      ? newdata.find((val: any) => val.id == cell.row.original.id)
                          ?.gp_verify_comment
                      : ''
                  }
                ></textarea>
              </div>
            ),
          },
          {
            Header: 'Verify',
            headerClassName: 'bg-light-primary min-w-175px',
            Cell: ({cell}: any) => (
              <div className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
                <div className='d-flex justify-content-between flex-shrink-0'>
                  <div className='form-check'>
                    <input
                      type='checkbox'
                      id={'submit_' + cell.row.original.id}
                      className='form-check-input input-pending'
                      onChange={(e) => {
                        handleData(cell.row.original, 'status', {
                          status: 'submit',
                          bool: e.target.checked,
                        })
                      }}
                      checked={
                        newdata
                          ? newdata.some(
                              (val: any) => val.id == cell.row.original.id && val.status == 'submit'
                            )
                          : false
                      }
                    />
                    <label className='form-check-label'>Submit</label>
                  </div>
                  <div className='form-check'>
                    <input
                      type='checkbox'
                      id={'return_' + cell.row.original.id}
                      className='form-check-input input-pending'
                      onChange={(e) => {
                        handleData(cell.row.original, 'status', {
                          status: 'return',
                          bool: e.target.checked,
                        })
                      }}
                      checked={
                        newdata
                          ? newdata.some(
                              (val: any) => val.id == cell.row.original.id && val.status == 'return'
                            )
                          : false
                      }
                      disabled={disabledChekd(cell.row.original.id)}
                    />
                    <label className='form-check-label'>Return</label>
                  </div>
                </div>
              </div>
            ),
          },
          {
            Header: <>Edit&nbsp;Item</>,
            id: 'new_item',
            headerClassName: 'bg-light-primary min-w-50px',
            Cell: ({cell}: any) => <NewItem data={cell.row.original} clearData={clearData} />,
          },
          {
            Header: <>Edit&nbsp;Supplier</>,
            id: 'new_supplier',
            headerClassName: 'bg-light-primary min-w-50px',
            Cell: ({cell}: any) => <NewSupplier data={cell.row.original} clearData={clearData} />,
          },
        ],
      },
      {
        Header: '',
        id: '1',
        headerClassName: 'bg-light-secondary min-w-95px',
        columns: [
          {
            Header: 'Action',
            id: 'action',
            headerClassName: 'bg-light-secondary',
            Cell: ({cell}: any) => <PendingShow data={cell.row.original} clearData={clearData} />,
          },
        ],
      },
    ],
    [data, newdata]
  )

  useEffect(() => {
    ChangeCount()
  }, [count, index, size, filters, newdata])

  return (
    <>
      <QSPendingTable
        columns={column}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        count={count}
        submit={submit}
      />
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const QSPandingList = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Pending List</PageTitle>
      <QSForm />
    </>
  )
}

export default QSPandingList
