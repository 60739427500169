import {useState, useEffect, useMemo, useRef, useCallback} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {TilesWidget5} from '../../../_metronic/partials/widgets'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {ReturnNull} from '../../modules/widgets/func/ReturnNull'
import {statusFormat} from '../../modules/widgets/quotation/func/QSStatus'
import {ReadMore} from '../../modules/widgets/func/ReadMore'
import {QSHomeListTable} from '../../modules/widgets/quotation/QSHomeListTable'
import {QSHOME, QSDel, QSCountAll} from '../../modules/api/requests/QuotationRequest'
import {QSShow} from '../quotations/Show'
import {QSEdit} from '../quotations/Edit'
import Swal from 'sweetalert2'
import * as PATH from '../../modules/path/Path'
import {BGLoad} from '../../modules/widgets/func/BackgroundLoading'
import {useCurrentPage} from '../master-setting/HistoryPage'
import Select from 'react-select'
import {DeptOption} from '../../modules/api/requests/DeptRequest'
import {customStyles2} from '../../modules/widgets/Styles/ReactSelect'
import {CoverTime3, CovertTime5} from '../../modules/widgets/func/CoverTime'
import DatePicker from 'react-datepicker'
import {BranchOption} from '../../modules/api/requests/BranchRequest'
import {SendCancelRequirement} from '../../modules/api/requests/MailRequest'

interface fetchAPI {
  limit: any
  skip: any
  status: any
  dept_id: any
  requestor_name: any
  branch_id: any
  request_date: any
}

const DashboardPage = () => {
  const [qs, setQS] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [qsstatus, setStatus] = useState<any>(null)
  const fetchIdRef = useRef(0)
  let index: number = 0
  let size: number = 0
  let newstatus: any = null
  const {currentPages} = useCurrentPage()
  const [departments, setDepartment] = useState([])
  const [branches, setBranch] = useState([])

  const [filterData, setFilterData] = useState<any>({
    requestor: '',
    dept: null,
    branch: null,
    request_date: null,
  })

  const fetchAPI = async ({
    limit,
    skip,
    status,
    dept_id,
    requestor_name,
    branch_id,
    request_date,
  }: fetchAPI) => {
    try {
      const res: any = await QSHOME({
        params: {
          limit: limit || 10,
          skip: skip || 0,
          status: encodeURIComponent(JSON.stringify(status)),
          dept_id: encodeURIComponent(JSON.stringify(dept_id)),
          requestor_name: encodeURIComponent(JSON.stringify(requestor_name)),
          branch_id: encodeURIComponent(JSON.stringify(branch_id)),
          request_date: encodeURIComponent(JSON.stringify(request_date)),
        },
      })
      let data: any = res.data.data
      let count: number = res.data.count
      let pCount: number = Math.ceil(count / limit)
      setData(data)
      setPageCount(pCount)
      setLoading(false)
    } catch (err) {
      console.log('Error while fecth')
    }
  }

  const fetchData = useCallback(({pageSize, pageIndex, status}: any) => {
    const fetchId = ++fetchIdRef.current
    setLoading(true)
    index = pageIndex
    size = pageSize
    newstatus = status
    if (fetchId == fetchIdRef.current) {
      fetchAPI({
        limit: pageSize,
        skip: pageSize * pageIndex,
        status: status,
        dept_id: filterData?.dept,
        requestor_name: filterData?.requestor,
        branch_id: filterData?.branch,
        request_date: filterData?.request_date,
      })
    }
  }, [])

  const getData = () => {
    fetchAPI({
      limit: size || 10,
      skip: size * index || 0,
      status: newstatus || null,
      dept_id: filterData?.dept,
      requestor_name: filterData?.requestor,
      branch_id: filterData?.branch,
      request_date: filterData?.request_date,
    })
  }

  const [count, setCount] = useState({
    count_none: '0',
    count_return: '0',
    count_submit: '0',
    count_processing: '0',
    count_finished: '0',
    count_revised: '0',
  })

  const getCount = async () => {
    const res: any = await QSCountAll()
    const data = res.data.data
    setCount((count: any) => ({
      ...count,
      count_none: data.count_none || 0,
      count_return: data.count_return || 0,
      count_submit: data.count_submit || 0,
      count_processing: data.count_processing || 0,
      count_finished: data.count_finished || 0,
      count_revised: data.count_revised || 0,
    }))
  }

  const getQSByStatus = async (st: string) => {
    newstatus = st
    index = 0
    size = 10
    if (currentPages) {
      currentPages.page = 'home'
      currentPages.size = 10
      currentPages.index = 0
      currentPages.status = true
    }
    fetchAPI({
      limit: 10,
      skip: 10 * 0 || 0,
      status: st || null,
      dept_id: filterData?.dept,
      requestor_name: filterData?.requestor,
      branch_id: filterData?.branch,
      request_date: filterData?.request_date,
    })
  }

  const getQSByFilter = async (type: string, value: any) => {
    try {
      index = 0
      size = 10

      if (currentPages) {
        currentPages.page = 'home'
        currentPages.size = 10
        currentPages.index = 0
        currentPages.status = true
      }

      const status = qsstatus || null
      const dept_id = type == 'dept' ? value : filterData?.dept
      const requestor_name = type == 'requestor' ? value : filterData?.requestor
      const branch_id = type == 'branch' ? value : filterData?.branch
      const request_date = type == 'request_date' ? value : filterData?.request_date

      fetchAPI({
        limit: 10,
        skip: 10 * 0 || 0,
        status: status,
        dept_id: dept_id,
        requestor_name: requestor_name,
        branch_id: branch_id,
        request_date: request_date,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const getQSByClearFilter = async () => {
    index = 0
    size = 10
    if (currentPages) {
      currentPages.page = 'home'
      currentPages.size = 10
      currentPages.index = 0
      currentPages.status = true
    }
    fetchAPI({
      limit: 10,
      skip: 10 * 0 || 0,
      status: qsstatus,
      dept_id: null,
      requestor_name: '',
      branch_id: null,
      request_date: null,
    })
  }

  const getDepartment = async () => {
    const res: any = await DeptOption()
    if (res.data.data.length > 0) {
      const options: any = res.data.data.map((val: any) => {
        return {value: val.id, label: val.dept_name}
      })
      setDepartment(options)
    }
  }

  const getBranch = async () => {
    const res: any = await BranchOption()
    if (res.data.data.length > 0) {
      const options: any = res.data.data.map((val: any) => {
        return {value: val.id, label: val.title}
      })
      setBranch(options)
    }
  }

  const del = async (id: number) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You confirm Delete',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSDel(id)
              .then(async (res) => {
                if (res.data.status == true) {
                  await SendCancelRequirement(id)
                  Swal.fire({
                    title: 'Deleted',
                    text: 'Successful Deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    getData()
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to delete',
                  text: 'Please try again.',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } catch (err) {
      console.error(err)
      Swal.fire({
        title: 'Failed to delete',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  useEffect(() => {
    getCount()
    getDepartment()
    getBranch()
  }, [qsstatus, index, size])

  const columns = useMemo(
    () => [
      {
        Header: <div className='min-w-150px'>Branch</div>,
        accessor: 'branch_title',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReadMore(cell.row.original.branch_title)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-150px'>Section/Department</div>,
        accessor: 'dept_title',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReadMore(cell.row.original.dept_title)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-50px'>GP&nbsp;no.</div>,
        accessor: 'job_title',
        Cell: ({cell}: any) => (
          <span className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
            {ReturnNull(cell.row.original.job_title)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-50px'>Job&nbsp;User&nbsp;no.</div>,
        accessor: 'job_user_no',
        Cell: ({cell}: any) => (
          <span className={cell.row.original.urgency == 1 ? 'text-danger' : ''}>
            {ReturnNull(cell.row.original.job_user_no)}
          </span>
        ),
      },
      {
        Header: <div>Request&nbsp;Date</div>,
        id: 'created_at',
        headerClassName: 'min-w-100px',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {CoverTime3(cell.row.original.created_at)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-150px'>Item</div>,
        accessor: 'item',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReturnNull(cell.row.original.item_title || cell.row.original.item_other)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-150px'>Item&nbsp;Description</div>,
        accessor: 'item_description',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReadMore(cell.row.original.item_description)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-50px'>Status</div>,
        accessor: 'status',
        Cell: ({cell}: any) => {
          return (
            <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
              {statusFormat(cell.row.original.status)}
            </span>
          )
        },
      },
      {
        Header: <div className='min-w-150px'>Requestor</div>,
        accessor: 'requestor_name',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReadMore(cell.row.original.requestor_name)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-150px'>Comment</div>,
        accessor: 'gp_verify_comment',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReadMore(cell.row.original.gp_verify_comment)}
          </span>
        ),
      },
      {
        Header: <div className='min-w-150px'>PIC</div>,
        accessor: 'pic',
        Cell: ({cell}: any) => (
          <span className={`word-break ${cell.row.original.urgency == 1 ? 'text-danger' : ''}`}>
            {ReadMore(cell.row.original.pic)}
          </span>
        ),
      },
      {
        Header: <div className='text-end'>Action</div>,
        id: 'action',
        Cell: ({cell}: any) => (
          <div className='d-flex justify-content-end flex-shrink-0'>
            <QSEdit data={cell.row.original} getData={getData} />
            <QSShow data={cell.row.original} />
            <button
              className='btn btn-icon btn-light-danger btn-sm'
              onClick={() => {
                del(cell.row.original.id)
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-md'>
          <div
            onClick={() => {
              getQSByStatus('none')
              setStatus((qsstatus: string) => 'none')
            }}
          >
            <TilesWidget5
              className='bg-light-primary'
              svgIcon='/media/icons/duotune/files/fil003.svg'
              titleClass='text-dark'
              descriptionClass='text-muted'
              iconClass='svg-icon-primary'
              title={count.count_none}
              description='None'
            />
          </div>
        </div>
        <div className='col-md'>
          <div
            onClick={() => {
              getQSByStatus('submit')
              setStatus((qsstatus: string) => 'submit')
            }}
          >
            <TilesWidget5
              className='bg-light-warning'
              svgIcon='/media/icons/duotune/files/fil025.svg'
              titleClass='text-dark'
              descriptionClass='text-muted'
              iconClass='svg-icon-warning'
              title={count.count_submit}
              description='Submit'
            />
          </div>
        </div>
        <div className='col-md'>
          <div
            onClick={() => {
              getQSByStatus('processing')
              setStatus((qsstatus: string) => 'processing')
            }}
          >
            <TilesWidget5
              className='bg-light-info'
              svgIcon='/media/icons/duotune/files/fil024.svg'
              titleClass='text-dark'
              descriptionClass='text-muted'
              iconClass='svg-icon-info'
              title={count.count_processing}
              description='Processing'
            />
          </div>
        </div>
        <div className='col-md'>
          <div
            onClick={() => {
              getQSByStatus('finished')
              setStatus((qsstatus: string) => 'finished')
            }}
          >
            <TilesWidget5
              className='bg-light-success'
              svgIcon='/media/icons/duotune/general/gen043.svg'
              titleClass='text-dark'
              descriptionClass='text-muted'
              iconClass='svg-icon-success'
              title={count.count_finished}
              description='Finished'
            />
          </div>
        </div>
        <div className='col-md'>
          <div
            onClick={() => {
              getQSByStatus('return')
              setStatus((qsstatus: string) => 'return')
            }}
          >
            <TilesWidget5
              className='bg-light-danger'
              svgIcon='/media/icons/duotune/arrows/arr029.svg'
              titleClass='text-dark'
              descriptionClass='text-muted'
              iconClass='svg-icon-danger'
              title={count.count_return}
              description='Return'
            />
          </div>
        </div>
        <div className='col-md'>
          <div
            onClick={() => {
              getQSByStatus('Revised')
              setStatus((qsstatus: string) => 'Revised')
            }}
          >
            <TilesWidget5
              className='bg-light-orange'
              svgIcon='/media/icons/duotune/general/gen055.svg'
              titleClass='text-dark'
              descriptionClass='text-muted'
              iconClass='svg-icon-orange'
              title={count.count_revised}
              description='Revised'
            />
          </div>
        </div>
        <div className='col-md-12'>
          <div className='row g-2'>
            <div className='col-lg-2'>
              <div className='d-flex flex-column'>
                <label htmlFor='filterRequestDate'>Request Date</label>
                <DatePicker
                  id='filterRequestDate'
                  className='form-control form-control-sm'
                  selected={filterData?.request_date ? new Date(filterData?.request_date) : null}
                  onChange={(date: any) => {
                    setFilterData({...filterData, request_date: CovertTime5(date)})
                    getQSByFilter('request_date', CovertTime5(date))
                  }}
                  dateFormat='yyyy/MM/dd'
                  maxDate={new Date('12-31-9999')}
                  placeholderText='yyyy/mm/dd'
                />
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='d-flex flex-column'>
                <label htmlFor='filterRequestor'>Requestor</label>
                <input
                  id='filterRequestor'
                  type='text'
                  className={'form-control form-control-sm'}
                  value={filterData?.requestor}
                  onChange={(e: any) => {
                    setFilterData({...filterData, requestor: e.target.value})
                    getQSByFilter('requestor', e.target.value)
                  }}
                  placeholder={'Filter Requestor data'}
                />
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='d-flex flex-column'>
                <label htmlFor='filterBranch'>Branch</label>
                <Select
                  id='filterBranch'
                  options={branches}
                  value={
                    branches
                      ? branches.find((option: any) => option.value === filterData?.branch) || null
                      : null
                  }
                  placeholder={'Filter branch data'}
                  onChange={(option: any) => {
                    setFilterData({...filterData, branch: option.value})
                    getQSByFilter('branch', option.value)
                  }}
                  styles={customStyles2}
                  menuPosition='fixed'
                />
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='d-flex flex-column'>
                <label htmlFor='filterDept'>Section/Department</label>
                <Select
                  id='filterDept'
                  options={departments}
                  value={
                    departments
                      ? departments.find((option: any) => option.value === filterData?.dept) || null
                      : null
                  }
                  placeholder={'Filter section/department data'}
                  onChange={(option: any) => {
                    setFilterData({...filterData, dept: option.value})
                    getQSByFilter('dept', option.value)
                  }}
                  styles={customStyles2}
                  menuPosition='fixed'
                />
              </div>
            </div>
            <div className='col-lg-1 d-flex align-items-end justify-content-end'>
              <button
                type='button'
                className='h-35px btn btn-secondary btn-sm'
                style={{width: '100%'}}
                onClick={() => {
                  setFilterData({
                    requestor: '',
                    dept: null,
                    branch: null,
                    request_date: null,
                  })
                  getQSByClearFilter()
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Notification status</span>
              </h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
              >
                <Link to={PATH.QS_LIST} className='btn btn-sm btn-light btn-active-primary'>
                  <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3' />
                  Goto Report
                </Link>
              </div>
            </div>
            <div className='card-body'>
              <QSHomeListTable
                columns={columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                status={qsstatus}
                type={'home'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Home</PageTitle>
      <DashboardPage />
      <BGLoad />
    </>
  )
}

export {DashboardWrapper}
